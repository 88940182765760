export const ResourceCategories = {
  video: { label: 'Video', icon: 'mdi-movie' },
  slides: { label: 'Slides', icon: 'mdi-format-indent-increase' },
  code: { label: 'Source Code', icon: 'mdi-xml' },
  demo: { label: 'Demo', icon: 'mdi-laptop' },
  dataset: { label: 'Dataset', icon: 'mdi-database' },
  website: { label: 'Website', icon: 'mdi-web' },
  talk: { label: 'Talk', icon: 'mdi-presentation' },
  blog: { label: 'Blog/Post', icon: 'mdi-post' }
}
