import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import UserAuth from '@/components/UserAuth.vue'
import { onUserSignIn } from '@/Firebase'

import { UserInfo } from '@/models'
const Account = () => import('@/views/Account.vue')
const Paperset = () => import('@/views/Paperset.vue')

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    redirect: '/s/'
  },
  {
    path: '/account/:subpage?',
    name: 'Account',
    component: Account,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/s/:papersetId?/:tab?',
    name: 'Paperset',
    component: Paperset,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/auth/:action',
    name: 'UserAuth',
    component: UserAuth
  }

  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const requireAuth = to.matched.some(path => path.meta.requireAuth)
  if (requireAuth) {
    onUserSignIn((user: UserInfo | null) => {
      if (!user) {
        next('/auth/signin?redirect=' + to.path)
      } else {
        next()
      }
    })
  } else {
    next()
  }
})

export default router
