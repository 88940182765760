import Vue from 'vue'
import Vuex, { StoreOptions } from 'vuex'
import { PaperId, PapersetId, UserProfile, PapersetNoteId } from '@/models'

Vue.use(Vuex)

export type SavedPdfTextProps = {
  paperId: PaperId
  papersetId: PapersetId
  text: string,
  textNodes?: HTMLElement[]
}

type StoreState = {
  papersetId: PapersetId | null
  userProfile: UserProfile | null
  savedPdfTextProps?: SavedPdfTextProps | null
  selectedNoteId?: PapersetNoteId | null
}

const store: StoreOptions<StoreState> = {
  state: {
    papersetId: null,
    userProfile: null,
    savedPdfTextProps: null,
    selectedNoteId: null
  },

  getters: {
    getPapersetId: state => () => {
      return state.papersetId
    },
    getUserProfile: state => () => {
      return state.userProfile
    },
    getSavedPdfText: state => () => {
      return state.savedPdfTextProps
    },
    getSelectedNote: state => () => {
      return state.selectedNoteId
    }
  },

  mutations: {
    setPapersetId (state, papersetId: PapersetId) {
      state.papersetId = papersetId
    },
    setUserProfile (state, userProfile: UserProfile) {
      state.userProfile = userProfile
    },
    setSavedPdfText (state, savedPdfTextProps: SavedPdfTextProps) {
      state.savedPdfTextProps = savedPdfTextProps
    },
    setSelectedNoteId (state, noteId: PapersetNoteId) {
      state.selectedNoteId = noteId
    }
  },

  actions: {
    setPapersetId (context, papersetId: PapersetId) {
      context.commit('setPapersetId', papersetId)
    },
    setUserProfile (context, userProfile: UserProfile) {
      context.commit('setUserProfile', userProfile)
    },
    setSavedPdfText (context, savedPdfTextProps: SavedPdfTextProps) {
      context.commit('setSavedPdfText', savedPdfTextProps)
    },
    setSelectedNoteId (context, note: PapersetNoteId) {
      context.commit('setSelectedNoteId', note)
    }
  }
}

export default new Vuex.Store(store)
