
import { defineComponent, reactive, toRefs, onMounted, computed } from '@vue/composition-api'
import { signIn, signOut, getSignInUser, signInWithEmail, signUpWithEmail } from '@/Firebase'
import { UserInfo } from '@/models'

type UserAuthData = {
  uiMode: number
  email: string | null
  password: string | null
  isSignedIn: boolean
  isSigningUp: boolean
  user: UserInfo | null
  showUI: boolean
  displayName: string
  redirectURL: string
  isFormValid: boolean
}

export default defineComponent({
  name: 'UserAuth',
  setup (props, { root, refs }) {
    const state: UserAuthData = reactive({
      uiMode: 0,
      email: null,
      password: null,
      isSignedIn: false,
      user: null,
      displayName: '',
      showUI: false,
      redirectURL: '/',
      isSigningUp: computed(() => state.uiMode === 1),
      isFormValid: true,
      nameRules: [
        (v: string) => !!v || 'Name is required',
        (v: string) => v.length <= 20 || 'Name must be less than 20 characters'
      ],
      emailRules: [
        (v: string) => !!v || 'E-mail is required',
        (v: string) => /.+@.+/.test(v) || 'E-mail must be valid'
      ],
      passwordRules: [(v: string) => !!v || 'Password is required']
    })

    const OauthProviders: Record<string, string>[] = [
      {
        name: 'Google',
        // image: 'google.png',
        iconColor: '#fff',
        icon: 'mdi-google',
        backgroundColor: '#ff3e30',
        textColor: '#fff',
        provider: 'google'
      },
      {
        name: 'GitHub',
        icon: 'mdi-github',
        iconColor: '#fff',
        textColor: '#fff',
        backgroundColor: '#222',
        provider: 'github'
      }
      // {
      //   name: 'Facebook',
      //   icon: 'fab fa-facebook',
      //   textColor: '#fff',
      //   backgroundColor: '#3b5998',
      //   provider: 'facebook'
      // }
    ]

    const redirect = () => {
      if (state.user !== null) {
        root.$router.push(state.redirectURL)
      }
    }

    onMounted(async () => {
      if (root.$route.params.action === 'signout') {
        console.log('signout')
        signOut()
        state.isSignedIn = false
        state.showUI = true
      } else {
        if (root.$route.query.redirect) {
          state.redirectURL = root.$route.query.redirect[0] ?? '/'
        }

        state.user = await getSignInUser()

        if (state.user !== null) {
          state.isSignedIn = true
          redirect()
        } else {
          state.isSignedIn = false
          state.showUI = true
        }
      }
    })

    const _signInWithEmail = async () => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const form: any = refs.emailForm
      form.validate()
      if (state.isFormValid && state.email && state.password) {
        state.user = await signInWithEmail(state.email, state.password)
        if (state.user) {
          redirect()
        }
      }
    }

    const _signUpWithEmail = async () => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const form: any = refs.emailForm
      form.validate()
      if (state.isFormValid && state.email && state.password) {
        state.user = await signUpWithEmail(state.email, state.password)
        if (state.user) {
          redirect()
        }
        // TODO: show error message in UI
      } else {
      }
    }

    return {
      ...toRefs(state),
      OauthProviders,
      _signUpWithEmail,
      _signInWithEmail,
      signIn
    }
  }
})
