import {
  UploadResult,
  UploadTask,
  getDownloadURL,
  getStorage,
  ref,
  uploadBytes,
  uploadBytesResumable,
  uploadString
} from 'firebase/storage'

import { generateRandomId } from '@/models/Id'

export function getPdfFilePath (papersetId: string, paperId: string): string {
  return `Collections/${papersetId}/PDFs/${paperId}.pdf`
}

export function getPdfThumbnailPath (papersetId: string, paperId: string): string {
  return `Collections/${papersetId}/Thumbs/${paperId}.png`
}

export function uploadPdfFile (
  papersetId: string,
  paperId: string,
  file: Blob | Uint8Array | ArrayBuffer
): UploadTask {
  return uploadBytesResumable(
    ref(getStorage(), getPdfFilePath(papersetId, paperId)),
    file
  )
}

export function uploadPdfFileAsBase64Str (
  papersetId: string,
  paperId: string,
  base64Str: string
): Promise<UploadResult> {
  return uploadString(ref(getStorage(), getPdfFilePath(papersetId, paperId)), base64Str, 'base64')
}

export async function getPdfFileUrl (
  papersetId: string,
  paperId: string
): Promise<string> {
  return getDownloadURL(ref(getStorage(), getPdfFilePath(papersetId, paperId)))
}

export function uploadPdfThumbnail (
  papersetId: string,
  paperId: string,
  dataUrl: string
): Promise<UploadResult> {
  return uploadString(ref(getStorage(), getPdfThumbnailPath(papersetId, paperId)), dataUrl, 'data_url')
}

export async function getPdfThumbnailUrl (
  papersetId: string,
  paperId: string
): Promise<string> {
  return getDownloadURL(ref(getStorage(), getPdfThumbnailPath(papersetId, paperId)))
}

export function uploadUserPhoto (
  userId: string,
  file: Blob | Uint8Array | ArrayBuffer | string
): Promise<UploadResult> {
  const photoRef = ref(getStorage(), `UserProfiles/${userId}/photo.png`)
  if (typeof file === 'string') {
    return uploadString(photoRef, file, 'data_url')
  } else {
    return uploadBytes(photoRef, file)
  }
}

export async function getUserPhotoUrl (
  userId: string
): Promise<string> {
  return getDownloadURL(ref(getStorage(), `UserProfiles/${userId}/photo.png`))
}

export async function uploadPapersetCoverImage (
  papersetId: string,
  dataUrl: string
): Promise<UploadResult> {
  return uploadString(ref(getStorage(), `Collections/${papersetId}/Thumbs/cover.png`), dataUrl, 'data_url')
}

export async function getPapersetCoverImageUrl (
  papersetId: string
): Promise<string> {
  return getDownloadURL(ref(getStorage(), `Collections/${papersetId}/Thumbs/cover.png`))
}

export async function uploadNoteImage ({
  papersetId,
  noteId,
  imageDataUrl,
  imageName
}: {
  papersetId: string,
  noteId: string,
  imageDataUrl: string,
  imageName?: string
}): Promise<UploadResult> {
  const imageId = imageName ?? 'img-' + generateRandomId()
  const imagePath = `Collections/${papersetId}/Notes/${noteId}/${imageId}.png`
  return uploadString(
    ref(getStorage(), imagePath),
    imageDataUrl,
    'data_url'
  )
}

export async function getNoteImage ({
  papersetId,
  noteId,
  imageId = 'preview'
}: {
  papersetId: string,
  noteId: string,
  imageId?: string
}): Promise<string> {
  const imagePath = `Collections/${papersetId}/Notes/${noteId}/${imageId}.png`
  return getDownloadURL(ref(getStorage(), imagePath))
}
