import {
  GoogleAuthProvider,
  EmailAuthProvider,
  GithubAuthProvider,
  User as FirebaseUser,
  createUserWithEmailAndPassword,
  getAuth,
  signInWithEmailAndPassword,
  signInWithRedirect,
  signOut as firebaseSignOut,
  getRedirectResult
} from 'firebase/auth'

import { UserInfo } from '../models/UserProfile'

export type AuthProviderName = 'google' | 'email' | 'github'

type AuthProviders = GoogleAuthProvider | EmailAuthProvider | GithubAuthProvider

export function createAuthProvider (providerName: AuthProviderName): AuthProviders {
  switch (providerName) {
  case 'google':
    return new GoogleAuthProvider()
  case 'email':
    return new EmailAuthProvider()
  case 'github':
    return new GithubAuthProvider()
  default:
    throw new Error('Invalid authorization provider name.')
  }
}

export function fetchUserInfo (firebaseUser: FirebaseUser): UserInfo | null {
  if (firebaseUser.email) {
    return {
      uid: firebaseUser.uid,
      name: firebaseUser.displayName ?? 'New User',
      email: firebaseUser.email,
      photo: firebaseUser.photoURL ?? ''
    }
  }
  return null
}

export function signIn (providerName: AuthProviderName = 'google'): Promise<void> {
  const authProvider = createAuthProvider(providerName)
  const auth = getAuth()
  return signInWithRedirect(auth, authProvider)
}

export function signOut (): Promise<void> {
  const auth = getAuth()
  return firebaseSignOut(auth)
}

export function getUser (): UserInfo | null {
  const auth = getAuth()
  const currentUser = auth.currentUser
  if (currentUser) {
    return fetchUserInfo(currentUser)
  }
  return null
}

export function requestUserInfo (callback: (user: UserInfo | null) => void): void {
  getAuth().onAuthStateChanged(user => {
    const authUser = (user !== null) ? fetchUserInfo(user) : null
    callback(authUser)
  })
}

export function onUserSignIn (callback: (user: UserInfo | null) => void): void {
  return requestUserInfo(callback)
}

export async function autoSignIn (): Promise<UserInfo> {
  return new Promise((resolve, reject) => {
    getAuth().onAuthStateChanged(user => {
      if (user) {
        const authUser = fetchUserInfo(user)
        if (authUser !== null) {
          resolve(authUser)
        }
        reject(new Error('Cannot fetch user information ' + user))
      }
      // reject(new Error('Failed to authorize user ' + user))
    })
  })
}

export async function getSignInUser (): Promise<UserInfo | null> {
  const auth = getAuth()
  try {
    const result = await getRedirectResult(auth)
    if (result !== null && result.user !== null) {
      return fetchUserInfo(result.user)
    }
  } catch (error) {
    // TODO: check if email account exists, like the same email have been used by multiple social media
    console.log(error)
  }
  return null
}

export async function signInWithEmail (
  email: string,
  password: string
): Promise<UserInfo | null> {
  const auth = getAuth()
  try {
    const result = await signInWithEmailAndPassword(auth, email, password)
    if (result !== null && result.user !== null) {
      return fetchUserInfo(result.user)
    }
  } catch (error) {
    // TODO: check if email account exists, like the same email have been used by multiple social media
    console.log(error)
  }
  return null
}

export async function signUpWithEmail (
  email: string,
  password: string
): Promise<UserInfo | null> {
  const auth = getAuth()
  try {
    const result = await createUserWithEmailAndPassword(auth, email, password)
    if (result !== null && result.user !== null) {
      return fetchUserInfo(result.user)
    }
  } catch (error) {
    // TODO: check if email account exists, like the same email have been used by multiple social media
    console.log(error)
  }
  return null
}
