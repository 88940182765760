declare const __value: unique symbol
declare const __type: unique symbol

type IId<T, S extends string> = {
  [__type]?: T;
  [__value]?: S;
}

export type Id<T, S extends string> = T & IId<T, S>

export type PapersetId = Id<string, 'PapersetId'>

export type PaperId = Id<string, 'PaperId'>

export type UserId = Id<string, 'UserId'>

export type PaperLabelId = Id<string, 'PaperLabelId'>

export type PaperLabelValueId = Id<string, 'PaperLabelValueId'>

export type InvitationId = Id<string, 'InvitationId'>

export const generateRandomId = (size = 7) : string => {
  return Math.random().toString(36).slice(2, size + 2) + (+new Date()).toString(36)
}
