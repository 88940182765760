import { PaperLabelId } from './Id'
import { Paper, PaperLabel } from './Paper'
import { UserInfo } from './UserProfile'

export type HighlightMarker = {
  label: string
  color: string
}

// TODO: Workaround for Firebase Server Timestamp.
// UPDATE: Upgraded to Firebase version 9. Is this still needed?)
export type serverTimestamp = {
  toDate: () => Date
}

export type Annotation = {
  id?: string
  paperId: string
  type: string
  timestamp: Date
  pinned: boolean
  colorId: number
  pageIndex?: number
  text?: string
  user?: UserInfo
}

export type Paperset = {
  id: string
  public: boolean
  name: string
  order?: number
  fetched?: boolean
  papers: Record<string, Paper>
  annotations: Record<string, Record<string, Annotation>>
  cover?: string // coverImage
  createdTime: Date
  lastUpdated?: Date
  description?: string
  readers?: string[]
  editors?: string[]
  markers: HighlightMarker[]
  owner: string
  paperIds?: string[]
  peopleInfo?: UserInfo[]
  paperLabels: Record<PaperLabelId, PaperLabel>
}

export enum Permission {
  CanEdit = 'can edit',
  ViewOnly = 'view only'
}

export const Permissions = Object.values(Permission)

export type Collaborator = {
  email: string
  permission: Permission
}
