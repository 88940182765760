import Vue from 'vue'
import Vuetify, {
  VApp,
  VAppBar,
  VAvatar,
  VMain,
  VBtn,
  VContainer,
  VCard,
  VCardTitle,
  VCardActions,
  VCardSubtitle,
  VCardText,
  VChip,
  VRow,
  VCol,
  VDialog,
  VDivider,
  VIcon,
  VList,
  VListGroup,
  VListItem,
  VListItemTitle,
  VListItemContent,
  VListItemAction,
  VListItemActionText,
  VListItemGroup,
  VToolbar,
  VTab,
  VTabItem,
  VTabsItems,
  VTabs,
  VNavigationDrawer,
  VSpacer,
  VToolbarTitle,
  VAppBarNavIcon,
  VMenu,
  VSubheader
} from 'vuetify/lib'

Vue.use(Vuetify)

Vue.component('VApp', VApp)
Vue.component('VAppBar', VAppBar)
Vue.component('VAppBarNavIcon', VAppBarNavIcon)
Vue.component('VAvatar', VAvatar)
Vue.component('VMain', VMain)
Vue.component('VContainer', VContainer)
Vue.component('VCard', VCard)
Vue.component('VCardActions', VCardActions)
Vue.component('VCardText', VCardText)
Vue.component('VCardTitle', VCardTitle)
Vue.component('VCardActions', VCardActions)
Vue.component('VCardSubtitle', VCardSubtitle)
Vue.component('VRow', VRow)
Vue.component('VCol', VCol)
Vue.component('VDialog', VDialog)
Vue.component('VDivider', VDivider)
Vue.component('VBtn', VBtn)
Vue.component('VIcon', VIcon)
Vue.component('VList', VList)
Vue.component('VListGroup', VListGroup)
Vue.component('VListItem', VListItem)
Vue.component('VListItemGroup', VListItemGroup)
Vue.component('VListItemTitle', VListItemTitle)
Vue.component('VListItemContent', VListItemContent)
Vue.component('VListItemAction', VListItemAction)
Vue.component('VListItemActionText', VListItemActionText)
Vue.component('VSpacer', VSpacer)
Vue.component('VToolbar', VToolbar)
Vue.component('VToolbarTitle', VToolbarTitle)
Vue.component('VTab', VTab)
Vue.component('VTabs', VTabs)
Vue.component('VTabItem', VTabItem)
Vue.component('VTabsItems', VTabsItems)
Vue.component('VNavigationDrawer', VNavigationDrawer)
Vue.component('VChip', VChip)
Vue.component('VMenu', VMenu)
Vue.component('VSubheader', VSubheader)

export default new Vuetify({
  icons: {
    iconfont: 'mdi'
  },
  theme: {
    themes: {
      light: {
        primary: '#1976D2',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        base: '#F6F6F8',
        panel: '#FFFFFF'
      }
    }
  }
})
