export const firebaseConfig = {
  apiKey: 'AIzaSyCEAVd-lYDMpMXcQcq3cm8DNmpXJBD6_GY',
  authDomain: 'papersetapp.firebaseapp.com',
  databaseURL: 'https://papersetapp.firebaseio.com',
  projectId: 'papersetapp',
  storageBucket: 'papersetapp.appspot.com',
  messagingSenderId: '716719941404',
  appId: '1:716719941404:web:848a20a32abfe61e137cd9',
  measurementId: 'G-ZD2W5P9ST9'
}

export const firebaseDevConfig = {
  apiKey: 'AIzaSyD4pWrtyF8-bOW4fkIq0AvRFMKVtV29sA8',
  authDomain: 'paperlax-dev.firebaseapp.com',
  databaseURL: 'https://paperlax-dev.firebaseio.com',
  projectId: 'paperlax-dev',
  storageBucket: 'paperlax-dev.appspot.com',
  messagingSenderId: '170742879748',
  appId: '1:170742879748:web:8f37e52a49fb5181cac751'
}
