import { PapersetId, PaperId, InvitationId, UserId } from './Id'
import { PaperAuthor } from './Paper'
import { UserNote, UserNoteId } from './UserNote'

export type PapersetInfo = {
  id: PapersetId
  name: string
  owner: string
  paperIds: string[]
  public: boolean
  description?: string
  canEdit?: boolean,
  createdTime: Date
  lastUpdated?: Date
  order?: number
}

export type PaperInfo = {
  id: PaperId
  title: string
  papersetId: PapersetId
  timestamp: Date
  authors?: PaperAuthor[]
  year?: number
  venue?: string
  pdfUrl?: string
}

export type UserInfo = {
  email: string
  name: string
  uid: string
  photo?: string
  displayName?: string
}

export enum InvitationStatus {
  Accepted = 'Accepted',
  Declined = 'Declined',
  Pending = 'Pending',
  Removed = 'Removed'
}

export const InvitationStatuses = Object.values(InvitationStatus)

export type Invitation = {
  id: InvitationId
  status: InvitationStatus
  permission: 'view only' | 'can edit'
  invitedBy?: UserInfo
  inviteeEmail: string
  paperset: PapersetInfo
  createdTime: Date
}

export type UserProfile = {
  id: UserId
  email: string
  displayName: string
  photoURL?: string
  uploadedPhoto?: string
  bio?: string
  affiliation?: string
  papersets: Record<PapersetId, PapersetInfo>
  papers: Record<PaperId, PaperInfo>
  invitations?: Invitation[]
  readHistory?: PaperId[]
  newlyAdded?: PaperId[]
  notes?: Record<UserNoteId, Partial<UserNote>>
  activity?: {
    papersetIdLastVisited: PapersetId
    isPdfViewerOn?: boolean
    isSideBrOn?: boolean
  }
}
