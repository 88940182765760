import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueCompositionAPI from '@vue/composition-api'
import vuetify from './plugins/vuetify'

import '@mdi/font/css/materialdesignicons.css'
import './app.css'
import '@/components/Notes/note-editor.scss'

import { initializeApp } from 'firebase/app'
import { firebaseConfig, firebaseDevConfig } from '../paperset.firebase.conf'
// import { useEmulators } from '@/Firebase/emulators'
Vue.config.productionTip = false

Vue.use(VueCompositionAPI)

// if (location.hostname === 'localhost') {
//   initializeApp(firebaseDevConfig)
//   // useEmulators()
// } else {
initializeApp(firebaseConfig)
// }

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App)
}).$mount('#app')
