import type {
  CollectionReference,
  DocumentData,
  DocumentReference,
  Firestore
} from 'firebase/firestore'
import {
  collection,
  doc,
  addDoc,
  deleteDoc,
  getDoc,
  setDoc,
  getFirestore,
  updateDoc,
  arrayRemove,
  arrayUnion
} from 'firebase/firestore'
import { Paper, PaperId, PapersetId, PaperType } from '@/models'

export class PaperDB {
  _db?: Firestore
  paperType: string

  constructor (paperType = PaperType.Papers) {
    this.paperType = paperType
  }

  get db (): Firestore {
    if (this._db === undefined) {
      this._db = getFirestore()
    }
    return this._db
  }

  private paperDoc (
    papersetId: PapersetId,
    paperId: PaperId
  ): DocumentReference<DocumentData> {
    return doc(this.db, 'Papersets', papersetId, this.paperType, paperId)
  }

  private papersetCollection (
    papersetId: PapersetId
  ): CollectionReference<DocumentData> {
    return collection(this.db, 'Papersets', papersetId, this.paperType)
  }

  async add (papersetId: PapersetId, paper: Paper): Promise<Paper> {
    if (typeof paper.id === 'string') {
      const paperDocRef = this.paperDoc(papersetId, paper.id)

      await setDoc(paperDocRef, paper)
    } else {
      const papersetColRef = this.papersetCollection(papersetId)
      const _paperInfo = await addDoc(papersetColRef, paper)
      paper.id = _paperInfo.id
    }
    return paper
  }

  async updateAndAdd (papersetId: PapersetId, papers: Paper[]): Promise<void> {
    if (!Array.isArray(papers) && typeof papers === 'object') {
      papers = [papers]
    }
    for (const paper of papers) {
      if (!paper.id) {
        throw new Error('Cannot add paper with invalid ID.')
      }
      const paperDocRef = this.paperDoc(papersetId, paper.id)
      getDoc(paperDocRef).then((paperSnapshot) => {
        if (paperSnapshot.exists()) {
          // if paper exist, only update the properties that are different
          const diff: Record<string, Partial<unknown>> = {}
          const paperDocData = paperSnapshot.data() as Paper
          if (paperDocData !== undefined) {
            for (const [key, value] of Object.entries(paperDocData)) {
              const pv = (paper as Record<string, unknown>)[key]
              if (pv !== value && value !== undefined) {
                diff[key] = value
              }
            }
            updateDoc(paperDocRef, diff)
          }
        } else {
          setDoc(paperDocRef, paper)
        }
      })
    }
  }

  async update (
    papersetId: PapersetId,
    paperId: PaperId,
    props: Record<string, Partial<unknown>>
  ): Promise<void> {
    const paperDocRef = this.paperDoc(papersetId, paperId)
    return updateDoc(paperDocRef, props)
  }

  async addArrayValue (
    papersetId: PapersetId,
    paperId: PaperId,
    field: string,
    value: unknown
  ): Promise<void> {
    const keyValue: Record<string, Partial<unknown>> = {}
    keyValue[field] = arrayUnion(value)
    const paperDocRef = this.paperDoc(papersetId, paperId)
    return updateDoc(paperDocRef, keyValue)
  }

  async removeArrayValue (
    papersetId: PapersetId,
    paperId: PaperId,
    field: string,
    value: unknown
  ): Promise<void> {
    const keyValue: Record<string, Partial<unknown>> = {}
    keyValue[field] = arrayRemove(value)
    const paperDocRef = this.paperDoc(papersetId, paperId)
    return updateDoc(paperDocRef, keyValue)
  }

  async get (papersetId: PapersetId, paperId: PaperId): Promise<Paper | null> {
    const paperDocRef = this.paperDoc(papersetId, paperId)

    const snapshot = await getDoc(paperDocRef)
    if (snapshot.exists()) {
      const paper = snapshot.data() as Paper
      paper.id = snapshot.id
      return paper
    }
    return null
  }

  async delete (papersetId: PapersetId, paperId: PaperId): Promise<void> {
    const paperDocRef = this.paperDoc(papersetId, paperId)
    return deleteDoc(paperDocRef)
  }
}
