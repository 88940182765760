import type {
  Firestore,
  DocumentData,
  DocumentReference
} from 'firebase/firestore'
import {
  getFirestore,
  Timestamp,
  collection,
  doc,
  addDoc,
  deleteDoc,
  getDocs,
  setDoc,
  updateDoc,
  query,
  where,
  limit
} from 'firebase/firestore'
import { getUser } from './auth'
import { PapersetId } from '@/models'

export type AssetRecord = Record<string, Partial<unknown>>

export type FirebaseQueryOptions = {
  orderBy: string
  limit: number
  startAt: unknown
}

export type newAssetOptions = {
  timestamp: boolean
  userInfo: boolean
}

export enum PapersetAssetType {
  PaperTexts = 'PaperTexts',
  Annotations = 'Annotations',
  Comments = 'Comments',
  References = 'References'
}

export const PapersetAssetTypes = Object.values(PapersetAssetType)

export class PapersetAssetDB {
  public assetType: PapersetAssetType
  public _db?: Firestore

  constructor (assetType: PapersetAssetType) {
    if (PapersetAssetTypes.indexOf(assetType) === -1) {
      throw new Error('Invalid metadata type')
    }
    this.assetType = assetType
  }

  get db (): Firestore {
    if (this._db === undefined) {
      this._db = getFirestore()
    }
    return this._db
  }

  async set (
    papersetId: PapersetId,
    assetId: string,
    asset: AssetRecord
  ): Promise<void> {
    setDoc(
      doc(this.db, 'Papersets', papersetId, this.assetType, assetId),
      asset
    )
  }

  async add (
    papersetId: PapersetId,
    asset: AssetRecord,
    options: newAssetOptions = { timestamp: false, userInfo: false }
  ): Promise<DocumentReference<DocumentData>> {
    const info: Record<string, unknown> = {}
    if (options.timestamp) {
      info.timestamp = Timestamp.fromDate(new Date())
    }
    if (options.userInfo) {
      const user = getUser()
      if (user !== null) {
        info.user = { name: user.name, uid: user.uid }
      }
    }
    return addDoc(
      collection(this.db, 'Papersets', papersetId, this.assetType),
      Object.assign(info, asset)
    )
  }

  async list (papersetId: PapersetId): Promise<AssetRecord[]> {
    const snapshots = await getDocs(
      collection(this.db, 'Papersets', papersetId, this.assetType)
    )

    const res: AssetRecord[] = []
    snapshots.forEach(s => {
      res.push(s.data())
    })

    return res
  }

  async get (
    papersetId: PapersetId,
    paperId: string,
    options: Partial<FirebaseQueryOptions> = {}
  ): Promise<AssetRecord[]> {
    const colRef = collection(
      this.db, 'Papersets', papersetId, this.assetType
    )
    const _limit = options.limit || 10

    const _query = query(
      colRef,
      where('paperId', '==', paperId),
      limit(_limit)
    )

    // TODO: add orderBy and startAt in query
    // firestore.Query = firestore()
    //   .collection('Papersets')
    //   .doc(papersetId)
    //   .collection(this.assetType)

    // if (typeof paperId === 'string') {
    //   dbRef = dbRef.where('paperId', '==', paperId)
    // }

    // if (options.orderBy) {
    //   dbRef = dbRef.orderBy(options.orderBy)
    // }
    // if (options.startAt) {
    //   dbRef = dbRef.startAt(options.startAt)
    // }
    // if (options.limit) {
    //   dbRef = dbRef.limit(options.limit)
    // }

    const docSnap = await getDocs(_query)
    if (docSnap.docs && docSnap.docs.length > 0) {
      return docSnap.docs.map(assetDoc => ({
        id: assetDoc.id,
        ...assetDoc.data()
      }))
    }
    return []
  }

  async update (
    papersetId: PapersetId,
    docId: string,
    props: Record<string, Partial<unknown>>
  ): Promise<void> {
    return updateDoc(
      doc(this.db, 'Papersets', papersetId, this.assetType, docId),
      props
    )
  }

  async delete (papersetId: PapersetId, docId: string): Promise<void> {
    return deleteDoc(
      doc(this.db, 'Papersets', papersetId, this.assetType, docId)
    )
  }
}
