import type {
  DocumentData,
  DocumentReference,
  FieldValue,
  Firestore
} from 'firebase/firestore'
import {
  Timestamp,
  arrayRemove,
  arrayUnion,
  collection,
  doc,
  addDoc,
  deleteDoc,
  deleteField,
  getDoc,
  getDocs,
  getFirestore,
  updateDoc
} from 'firebase/firestore'

import {
  PapersetId,
  Paperset,
  PaperId,
  Paper,
  Annotation,
  PaperType,
  Invitation,
  Collaborator,
  Permission
} from '@/models'

import { PaperDB } from './PaperDB'
import { getUser } from './auth'
import { PapersetAssetDB, PapersetAssetType } from './PapersetAssetDB'
import { UserProfileDB } from './UserProfileDB'
import xxhash from 'xxhashjs'

export function genArtHash (
  firstAuthorSurname: string,
  year: number,
  title: string
): string | null {
  if (!firstAuthorSurname || !year || !title) {
    return null
  }
  const artTitle = title.replace(/\s+/g, ' ').trim().toLowerCase()
  const seed = 0xC
  const radix = 16
  const hash =
    xxhash.h64(artTitle, seed).toString(radix)
  return `${firstAuthorSurname.toLowerCase()}-${year}-${hash}`
}

export const PapersetDB = {

  get firestore (): Firestore { return getFirestore() },

  Papers: new PaperDB(),

  References: new PaperDB(PaperType.References),

  PaperTexts: new PapersetAssetDB(PapersetAssetType.PaperTexts),

  Annotations: new PapersetAssetDB(PapersetAssetType.Annotations),

  add (paperset: Paperset): Promise<DocumentReference<DocumentData>> {
    return addDoc(
      collection(this.firestore, 'Papersets'),
      paperset
    )
  },

  update (
    PapersetId: PapersetId, props: Record<string, Partial<unknown>>): Promise<void> {
    const updatingProps = Object.assign({
      lastUpdated: Timestamp.fromDate(new Date())
    }, props)

    for (const propKey in props) {
      if (props[propKey] === null || props[propKey] === undefined) {
        updatingProps[propKey] = deleteField()
      }
    }
    return updateDoc(doc(this.firestore, `Papersets/${PapersetId}`), updatingProps)
  },

  async get (PapersetId: PapersetId): Promise<Paperset | undefined> {
    try {
      const snapshot = await getDoc(
        doc(this.firestore, `Papersets/${PapersetId}`)
      )

      if (snapshot.exists()) {
        const data = snapshot.data()
        if (data.createdTime && typeof data.createdTime.toDate === 'function') {
          data.createdTime = data.createdTime.toDate()
        }
        if (data.lastUpdated && typeof data.lastUpdated.toDate === 'function') {
          data.lastUpdated = data.lastUpdated.toDate()
        }

        if (data.papers) {
          Object.values(data.papers as Paper[]).forEach((paper) => {
            if (paper.timeAdded) {
              const timestamp = paper.timeAdded as Date & {toDate: () => Date}
              paper.timeAdded = timestamp.toDate()
            }
          })
        }

        return { id: PapersetId, ...data } as Paperset
      }
    } catch (error) {
      throw new Error(`"${PapersetId}" not found in Papersets.`)
    }
  },

  delete (papersetId: PapersetId): Promise<void> {
    // First, delete the cached paperset info in the user account
    UserProfileDB.removePaperset(papersetId)

    // TODO: need to also delete cached collection info for all collaborating users

    // Delete from the Papersets collection in firestore
    return deleteDoc(doc(this.firestore, `Papersets/${papersetId}`))
  },

  generateIdentifier (paper: Paper): string | null {
    const paperId = genArtHash(paper.authors[0].family, paper.year ?? 0, paper.title)
    return paperId
  },

  // TODO: create cloud functions for updating these props when the paper doc is updated
  CachedPaperProps: [
    'title', 'authors', 'year', 'venue', 'keywords', 'remark', 'hasNote',
    'labels', 'hasPDF', 'star', 'thumb', 'timeAdded', 'resources', 'paperLabels'
  ],

  async addPaper (papersetId: PapersetId, paper: Paper): Promise<PaperId> {
    const newPaper = Object.assign({
      identifier: this.generateIdentifier(paper),
      timeAdded: Timestamp.fromDate(new Date()),
      addedBy: getUser()
    }, paper) as Paper & {[key: string]: Partial<unknown>}
    const paperDoc = await PapersetDB.Papers.add(papersetId, newPaper)
    if (!newPaper.id) {
      newPaper.id = paperDoc.id
    }
    PapersetDB.Annotations.set(papersetId, newPaper.id, {})
    const papersetInfo: Record<string, Partial<unknown>> = {
      paperIds: arrayUnion(newPaper.id),
      lastUpdated: Timestamp.fromDate(new Date())
    }
    const paperKey = 'papers.' + newPaper.id
    const paperInfo: Record<string, Partial<unknown>> = {}
    this.CachedPaperProps.forEach(prop => {
      if (newPaper[prop] !== undefined) {
        paperInfo[prop] = newPaper[prop]
      }
    })
    paperInfo.hasAbstract = !!((newPaper.abstract && newPaper.abstract.length > 3))
    paperInfo.hasNote = false

    papersetInfo[paperKey] = paperInfo

    updateDoc(
      doc(this.firestore, `Papersets/${papersetId}`),
      papersetInfo
    )

    return newPaper.id
  },

  async updatePaper (
    papersetId: PapersetId,
    paperId: PaperId,
    props: Record<string, Partial<unknown>>
  ): Promise<void> {
    const cachedProps: Record<string, Partial<unknown>> = {}
    Object.keys(props).forEach(prop => {
      if (props[prop] === null || props[prop] === undefined) {
        props[prop] = deleteField()
      }
      if (this.CachedPaperProps.indexOf(prop) !== -1 || prop.startsWith('resources')) {
        const paperKey = ['papers', paperId, prop].join('.')
        cachedProps[paperKey] = props[prop]
      }
    })
    if (props.abstract) {
      const paperKey = ['papers', paperId, 'hasAbstract'].join('.')
      cachedProps[paperKey] = (typeof props.abstract === 'string' && props.abstract.length > 0)
    }
    if (props.note) {
      const paperKey = ['papers', paperId, 'hasNote'].join('.')
      cachedProps[paperKey] = (typeof props.note === 'string' && props.note.length > 0)
    }

    updateDoc(doc(this.firestore, `Papersets/${papersetId}`), cachedProps)

    return this.Papers.update(papersetId, paperId, props)
  },

  async deletePaper (papersetId: PapersetId, paperId: PaperId): Promise<void> {
    await PapersetDB.Papers.delete(papersetId, paperId)
    await PapersetDB.PaperTexts.delete(papersetId, paperId)
    await PapersetDB.Annotations.delete(papersetId, paperId)
    const props: Record<string, Partial<unknown>> = { paperIds: arrayRemove(paperId) }
    const paperKey = 'papers.' + paperId
    const annotationKey = 'annotations.' + paperId
    props[paperKey] = deleteField()
    props[annotationKey] = deleteField()
    props.lastUpdated = Timestamp.fromDate(new Date())
    return updateDoc(doc(this.firestore, `Papersets/${papersetId}`), props)
  },

  async addAnnotation (
    papersetId: PapersetId,
    paperId: PaperId,
    annotation: Annotation,
    external = false
  ): Promise<string> {
    const user = getUser()
    const itemId = Math.random().toString(36).slice(2, 9) + (+new Date()).toString(36)
    const newItem: Record<string, Partial<unknown>> = {}
    const values = {
      ...annotation,
      timestamp: Timestamp.fromDate(new Date()),
      user: { name: user?.name, uid: user?.uid, email: user?.email }
    }

    if (external) {
      newItem[itemId] = values
      await updateDoc(
        doc(this.firestore, `Papersets/${papersetId}/Annotations/${paperId}`),
        newItem
      )
    } else {
      const itemKey = ['annotations', paperId, itemId].join('.')
      newItem[itemKey] = values
      await updateDoc(
        doc(this.firestore, `Papersets/${papersetId}`),
        newItem
      )
    }
    return itemId
  },

  updateAnnotation (
    papersetId: PapersetId,
    paperId: PaperId,
    annotation: Annotation,
    external = false
  ): Promise<void> {
    const item: Record<string, Partial<unknown>> = {}
    if (external) {
      if (annotation.id !== undefined) {
        item[annotation.id] = annotation
      }
      return updateDoc(
        doc(this.firestore, `Papersets/${papersetId}/Annotations/${paperId}`),
        item
      )
    } else {
      const itemKey = ['annotations', paperId, annotation.id].join('.')
      item[itemKey] = annotation
      return updateDoc(
        doc(this.firestore, `Papersets/${papersetId}`),
        item
      )
    }
  },

  deleteAnnotation (
    papersetId: PapersetId,
    paperId: PaperId,
    annotationId: string,
    external = false
  ): Promise<void> {
    const item: Record<string, Partial<unknown>> = {}
    if (external) {
      item[annotationId] = deleteField()
      return updateDoc(
        doc(this.firestore, `Papersets/${papersetId}/Annotations/${paperId}`),
        item
      )
    } else {
      const itemKey = ['annotations', paperId, annotationId].join('.')
      item[itemKey] = deleteField()
      return updateDoc(
        doc(this.firestore, `Papersets/${papersetId}`),
        item
      )
    }
  },

  async getPaperAnnotations (
    papersetId: PapersetId,
    paperId: PaperId
  ): Promise<Annotation[]> {
    const snapshot = await getDoc(
      doc(this.firestore, `Papersets/${papersetId}/Annotations/${paperId}`)
    )

    let items: Annotation[] = []
    if (snapshot.exists()) {
      const annotations = snapshot.data()

      if (annotations) {
        items = Object.keys(annotations).map(key => {
          return { id: key, ...annotations[key] }
        })
      }
    }
    return items
  },

  async getAnnotations (PapersetId: PapersetId): Promise<Annotation[]> {
    const annotations = await getDocs(
      collection(this.firestore, `Papersets/${PapersetId}/Annotations`)
    )

    let results: Annotation[] = []
    annotations.docs.forEach(doc => {
      const items = doc.data()
      if (items) {
        results = results.concat(Object.keys(items).map(key => {
          return { id: key, ...items[key] }
        }))
      }
    })
    return results
  },

  importAnnotations (
    papersetId: PapersetId,
    paperId: PaperId,
    annotations: Annotation[]
  ): Promise<void> {
    return updateDoc(
      doc(this.firestore, `Papersets/${papersetId}/Annotations/${paperId}`),
      annotations
    )
  },

  addCollaborator (
    paperset: Paperset,
    user: Collaborator
  ): Promise<void> {
    const newCollaborator: Partial<Invitation> = {
      paperset: {
        id: paperset.id,
        name: paperset.name,
        description: paperset.description,
        canEdit: user.permission === Permission.CanEdit,
        paperIds: paperset.paperIds ?? [],
        public: paperset.public,
        owner: paperset.owner,
        createdTime: paperset.createdTime
      },
      inviteeEmail: user.email,
      permission: user.permission
    }

    addDoc(
      collection(this.firestore, 'Invitations'),
      {
        createdTime: Timestamp.fromDate(new Date()),
        ...newCollaborator
      }
    )
    const updateValue: Record<string, Partial<unknown>> = {}
    if (user.permission === 'can edit') {
      updateValue.editors = arrayUnion(user.email)
    } else {
      updateValue.readers = arrayUnion(user.email)
    }
    return updateDoc(doc(this.firestore, `Papersets/${paperset.id}`), updateValue)
  },

  removeCollaborator (
    paperset: Paperset,
    user: {email: string; permission: string}
  ): Promise<void> {
    const updateValue: {readers?: FieldValue; editors?: FieldValue} = {}
    if (user.permission === 'can edit') {
      updateValue.editors = arrayRemove(user.email)
    } else {
      updateValue.readers = arrayRemove(user.email)
    }
    return updateDoc(doc(this.firestore, `Papersets/${paperset.id}`), updateValue)
  },

  addArrayValue (papersetId: PapersetId, field: string, value: unknown): Promise<void> {
    const keyValue: Record<string, Partial<unknown>> = {}
    keyValue[field] = arrayUnion(value)
    return updateDoc(
      doc(this.firestore, `Papersets/${papersetId}`),
      keyValue
    )
  },

  removeArrayValue (
    papersetId: PapersetId, field: string, value: unknown
  ): Promise<void> {
    const keyValue: Record<string, Partial<unknown>> = {}
    keyValue[field] = arrayRemove(value)
    return updateDoc(
      doc(this.firestore, `Papersets/${papersetId}`),
      keyValue
    )
  }

  // async list (): Promise<Promise<firestore.QuerySnapshot<firestore.DocumentData>> | null> {
  //   const user = getUser()
  //   if (user) {
  //     return firestore()
  //       .collection('Papersets')
  //       .where('owner', '==', user.email)
  //       .get()
  //   }
  //   return null
  // },

  // async listPublicSets (): Promise<Paperset[]> {
  //   const paperSets = await firestore()
  //     .collection('Papersets')
  //     .where('public', '==', true)
  //     .limit(10)
  //     .get()

  //   return paperSets.docs
  //     .map(collection => ({
  //       id: collection.id,
  //       ...collection.data()
  //     } as Paperset))
  // },

  // async getPublicSets (): Promise<Paperset[]> {
  //   const paperSets = await firestore()
  //     .doc('Public/PaperSets')
  //     .get()

  //   return paperSets.data() as Paperset[]
  // }
}
