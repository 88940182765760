import {
  Timestamp,
  collection,
  doc,
  addDoc,
  deleteDoc,
  getDoc,
  setDoc,
  getFirestore,
  query,
  where,
  updateDoc,
  arrayRemove,
  arrayUnion,
  deleteField,
  getDocs,
  documentId
} from 'firebase/firestore'
import { autoSignIn, getUser } from './auth'

import {
  PapersetId,
  PaperId,
  Paperset,
  Paper,
  PapersetInfo,
  Invitation,
  UserProfile,
  InvitationStatus,
  PaperInfo,
  UserInfo
} from '@/models'

export const UserProfileDB = {

  async getProfile (): Promise<UserProfile | null> {
    const user = await autoSignIn()
    if (!user) {
      return null
    }
    const db = getFirestore()
    const profileDoc = doc(db, 'UserProfiles', user.uid)
    const snapshot = await getDoc(profileDoc)

    if (!snapshot.exists()) {
      console.log('setup new user profile')
      const profile = await this.setup()
      return profile
    } else {
      const userProfile = snapshot.data() as Omit<UserProfile, 'id'>
      const papersets = Object.values(
        userProfile.papersets
      ) as ({ createdTime: Timestamp } & Partial<Paperset>)[]
      papersets.forEach(paperset => {
        const createdTime = paperset.createdTime
        Object.assign(paperset, {
          createdTime: createdTime?.toDate ? createdTime.toDate() : null
        })
      })
      return { id: snapshot.id, ...userProfile }
    }
  },

  async setup (): Promise<UserProfile | null> {
    const user = getUser()
    if (user === null) {
      return null
    }
    let invitations: Invitation[] = []
    const db = getFirestore()
    const _query = query(
      collection(db, 'Invitations'),
      where('inviteeEmail', '==', user.email)
    )
    const snapshots = await getDocs(_query)

    if (snapshots.docs.length) {
      invitations = snapshots.docs
        .map(invitation => {
          return {
            id: invitation.id,
            ...invitation.data()
          } as Invitation
        })
    }

    const firstPaperset = await this.addPaperset({
      name: 'Read Later',
      description: '',
      public: false
    }, false)

    const papersets: Record<string, Paperset> = {}

    if (firstPaperset !== null) {
      papersets[firstPaperset.id] = firstPaperset
    }
    const profile: UserProfile = {
      id: user.uid,
      displayName: user.name,
      email: user.email,
      photoURL: user.photo ?? '',
      readHistory: [],
      newlyAdded: [],
      invitations,
      papersets,
      papers: {}
    }

    const profileDoc = doc(db, 'UserProfiles', user.uid)
    await setDoc(profileDoc, profile)

    return profile
  },

  async update (props: Record<string, Partial<unknown>>): Promise<void> {
    const user = getUser()
    if (user !== null) {
      const profileDoc = doc(getFirestore(), 'UserProfiles', user.uid)
      return updateDoc(profileDoc, props)
    }
  },

  async getPapersets (papersetIds: PapersetId[]): Promise<Paperset[]> {
    let papersets: Paperset[] = []
    try {
      const _query = query(
        collection(getFirestore(), 'Papersets'),
        where(documentId(), 'in', papersetIds)
      )
      const snaps = await getDocs(_query)

      papersets = snaps.docs.map(
        (papersetDoc) => {
          const papersetData = papersetDoc.data()
          const createdTime: Timestamp = papersetDoc.get('createdTime')
          return {
            id: papersetDoc.id as PapersetId,
            ...Object.assign(papersetData, {
              createdTime: createdTime?.toDate ? createdTime.toDate() : null
            })
          } as Paperset
        })
    } catch (error) {
      if (error.name === 'FirebaseError' && error.code === 'permission-denied') {
        // TODO: Remove the shared collection since the user no longer has access to it
        console.error(error)
      }
    }
    return papersets
  },

  updatePapersetInfo (
    userProfile: UserProfile,
    paperset: Paperset
  ): Record<string, Partial<unknown>> {
    const props: Record<string, Partial<unknown>> = {}

    const currentPaperset = (Object.values(userProfile.papersets) as PapersetInfo[])
      .find(ps => ps.id === paperset.id)

    if (currentPaperset !== undefined) {
      const propPrefix = `papersets.${paperset.id}`
      if (!Array.isArray(currentPaperset.paperIds) && paperset.paperIds) {
        props[propPrefix + '.paperIds'] = paperset.paperIds
      } else {
        if (
          paperset.paperIds &&
          currentPaperset.paperIds.length !== paperset.paperIds?.length
        ) {
          props[propPrefix + '.paperIds'] = paperset.paperIds
        }
      }
      if (currentPaperset.name !== paperset.name) {
        props[propPrefix + '.name'] = paperset.name
      }
      if (paperset.description && currentPaperset.description !== paperset.description) {
        props[propPrefix + '.description'] = paperset.description
      }
    }

    if (Object.keys(props).length > 0) {
      const user = getUser()
      if (user !== null) {
        const profileDoc = doc(getFirestore(), 'UserProfiles', user.uid)
        updateDoc(profileDoc, props)
      }
    }

    return props
  },

  async addPaperset (
    paperSetInfo: Partial<PapersetInfo>,
    isSavingToUserProfile = true,
    order = -1
  ): Promise<Paperset | null> {
    const user = getUser()
    if (user === null) return null
    const createdTime = new Date()

    const newPaperSet = {
      ...paperSetInfo,
      paperIds: [],
      papers: {},
      owner: user.email,
      annotations: {},
      peopleInfo: [user],
      markers: [
        { label: 'Highlight 1', color: '#f1c40f' },
        { label: 'Highlight 2', color: '#1abc9c' }
      ],
      paperLabels: {},
      order
    }
    const db = getFirestore()
    const papersetRef = collection(db, 'Papersets')
    const newDoc = await addDoc(papersetRef, {
      ...newPaperSet,
      createdTime: Timestamp.fromDate(createdTime)
    })
    if (!newPaperSet.id) {
      newPaperSet.id = newDoc.id
    }

    if (isSavingToUserProfile) {
      const props: Record<string, Partial<unknown>> = {}
      props['papersets.' + newDoc.id] = {
        ...newPaperSet,
        createdTime: Timestamp.fromDate(createdTime)
      }

      const profileDoc = doc(db, 'UserProfiles', user.uid)
      updateDoc(profileDoc, props)
    }

    return { createdTime, ...newPaperSet } as Paperset
  },

  async removePaperset (papersetId: PapersetId): Promise<void> {
    const user = getUser()
    if (user !== null) {
      const props: Record<string, Partial<unknown>> = {}
      props['papersets.' + papersetId] = deleteField()
      const profileDoc = doc(getFirestore(), 'UserProfiles', user.uid)
      return updateDoc(profileDoc, props)
    }
  },

  maxNumberOfPapersInHistory: 100,

  async addToHistory (paper: Paper, historyType = 'readHistory'): Promise<void> {
    const user = getUser()
    if (user === null) return

    const profileDoc = doc(getFirestore(), 'UserProfiles', user.uid)

    const profileSnapshot = await getDoc(profileDoc)
    const profile = profileSnapshot.data() ?? {}
    const props: Record<string, Partial<unknown>> = {}
    if (profile[historyType].indexOf(paper.id) === -1) {
      props[historyType] = arrayUnion(paper.id)
      const paperInfo: PaperInfo = {
        // TODO: define the type for this object!
        id: paper.id,
        title: paper.title,
        authors: paper.authors,
        year: paper.year ?? 0,
        venue: paper.venue ?? '',
        pdfUrl: paper.pdfUrl ?? '',
        papersetId: paper.papersetId,
        timestamp: new Date()
      }
      props['papers.' + paper.id] = paperInfo
    } else {
      props[['papers', paper.id, 'timestamp'].join('.')] = new Date()
      props[['papers', paper.id, 'papersetId'].join('.')] = paper.papersetId
      if (paper.pdfUrl) {
        props[['papers', paper.id, 'pdfUrl'].join('.')] = paper.pdfUrl
      }
      if (profile.papers[paper.id].title !== paper.title) {
        props[['papers', paper.id, 'title'].join('.')] = paper.title
      }
    }

    const accessedPapers: Paper[] = profile[historyType]
      .map((paperId: PaperId) => profile.papers[paperId])
      .sort((a: PaperInfo, b: PaperInfo) => a.timestamp < b.timestamp ? -1 : 1)

    if (accessedPapers.length > this.maxNumberOfPapersInHistory) {
      const paperToRemove = accessedPapers[0]
      props[historyType] = arrayRemove(paperToRemove.id)
      props['papers.' + paperToRemove.id] = deleteField()
    }
    return updateDoc(profileDoc, props)
  },

  async removeFromHistory (paperId: string, historyType = 'readHistory'): Promise<void> {
    const user = getUser()
    if (user === null) return

    const props: Record<string, Partial<unknown>> = {}
    props[historyType] = arrayRemove(paperId)
    props['papers.' + paperId] = deleteField()

    const profileDoc = doc(getFirestore(), 'UserProfiles', user.uid)
    return updateDoc(profileDoc, props)
  },

  async savePaperset (paperset: PapersetInfo): Promise<void> {
    const user = getUser()
    if (user !== null) {
      const newPaperset: Record<string, Partial<unknown>> = {}
      newPaperset['papersets.' + paperset.id] = paperset

      const profileDoc = doc(getFirestore(), 'UserProfiles', user.uid)
      return updateDoc(profileDoc, newPaperset)
    }
  },

  async removeCollection (papersetId: PapersetId): Promise<void> {
    const user = getUser()
    if (user !== null) {
      const toDelete: Record<string, Partial<unknown>> = {}
      toDelete['papersets.' + papersetId] = deleteField()
      const profileDoc = doc(getFirestore(), 'UserProfiles', user.uid)
      return updateDoc(profileDoc, toDelete)
    }
  },

  async acceptInvitation (invitation: Invitation): Promise<void> {
    const user = getUser()
    if (user === null) return

    const props: Record<string, Partial<unknown>> = {
      invitations: arrayRemove(invitation)
    }
    props['papersets.' + invitation.paperset.id] = invitation.paperset
    const db = getFirestore()

    const profileDoc = doc(db, 'UserProfiles', user.uid)
    updateDoc(profileDoc, props)

    return deleteDoc(doc(db, 'Invitations', invitation.id))
  },

  async declineInvitation (invitation: Invitation): Promise<void> {
    const user = getUser()
    if (user === null) return
    const db = getFirestore()
    updateDoc(
      doc(db, 'UserProfiles', user.uid),
      { invitations: arrayRemove(invitation) }
    )

    updateDoc(
      doc(db, 'Invitations', invitation.id),
      { status: InvitationStatus.Declined }
    )
  },

  async getInvitations (): Promise<Partial<Invitation>[] | null> {
    const user = getUser()
    if (user === null) return null

    const db = getFirestore()
    const _query = query(
      collection(db, 'Invitations'),
      where('userEmail', '==', user.email)
    )
    const snapshots = await getDocs(_query)

    return snapshots.docs
      .map(invitation => ({
        id: invitation.id,
        ...invitation.data()
      }))
  },

  async getUserInfoByEmails (userEmails: string[]): Promise<UserInfo[]> {
    const user = getUser()
    if (user === null) return []

    const db = getFirestore()
    const _query = query(
      collection(db, 'UserProfiles'),
      where('email', 'in', userEmails)
    )
    const snapshots = await getDocs(_query)

    return snapshots.docs.map(profile => {
      const { email, displayName, photoURL } = profile.data()
      return {
        uid: profile.id,
        email,
        name: displayName,
        photo: photoURL
      }
    }
    )
  }

}
