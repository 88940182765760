import { PaperLabelId, PaperLabelValueId, PapersetId } from './Id'

export type PaperAuthor = {
  family: string
  given: string
}

export type PaperResource = {
  category: string
  url: string
}

export type PaperUploadTask = {
  status: 'uploading' | 'uploaded' | 'error'
  progress: number
  detail?: string
}

export enum PaperType {
  Papers = 'Papers',
  References = 'References'
}

export const PaperTypes = Object.values(PaperType)

export type Paper = {
  id: string
  papersetId: PapersetId
  title: string
  authors: PaperAuthor[]
  doi?: string
  identifier?: string
  year: number
  venue?: string
  abstract?: string
  timeAdded: Date
  labels?: PaperLabelValueId[]
  note?: string
  remark?: string
  keywords?: string | string[]
  resources?: Record<string, string>
  resourceLinks?: PaperResource[]
  thumb?: string
  pdfUrl?: string
  isRemotePdfUrl?: boolean
  hasPDF?: boolean
  star?: boolean
  hasAbstract?: boolean
  hasNote?: boolean
  hasRemark?: boolean
  references?: {index: number, text: string}[]
  uploadTask?: PaperUploadTask
}

export enum PaperResourceType {
  Video = 'video',
  Slides = 'slides',
  Code = 'code',
  Demo = 'demo',
  Dataset = 'dataset',
  Website = 'website',
  Talk = 'talk',
  Blog = 'blog'
}

export const PaperResourceTypes = Object.values(PaperResourceType)

export type PaperFilter = {
  years?: (number[])[]
  labels?: PaperLabelValueId[]
  keywords?: string[]
  searchText?: string
  hasRemark?: boolean
  hasAbstract?: boolean
  hasPDF?: boolean
}

export enum PaperLabelType {
  SingleTag = 'SingleTag',
  SingleSelect = 'SingleSelect',
  MultiSelect = 'MultiSelect'
}

export const PaperLabelTypes = Object.values(PaperLabelType)

export type PaperLabelValue = {
  id: PaperLabelValueId
  text: string
  color?: string
  order?: number
  description?: string
}

export type PaperLabel = {
  id: PaperLabelId
  name: string
  type: PaperLabelType
  value: PaperLabelValue[]
  order?: number
  isDeleted?: boolean
}
